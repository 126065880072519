<template>
    <div>
        <van-image
            width="100%"
            :src="note.live.poster"
            @load="() => { this.imgLoaded = true }"
            :style="{ height: imgLoaded ? null : '180px' }"
        />

        <van-cell title="" v-if="loading">
            <template #label>
                <van-skeleton title :row="5" style="padding: 0px" />
            </template>
        </van-cell>

        <template v-else>
            <t-content>
                <template #title>{{ note.live.title }}</template>
                <template #meta>
                    {{ note.live.author }}
                    <span class="divider">/</span>
                    {{ note.live.live_at | dateFormatter }}
                </template>
                <template #content>
                    <div class="note-content" v-html="note.content"></div>
                </template>
            </t-content>
        </template>
    </div>
</template>

<script>
import { getNote } from '@/api/note'
import TContent from '@/components/TContent'
import { dateFormat } from '@/utils/util'

export default {
    name: 'NoteShow',

    data() {
        return {
            loading: true,
            imgLoaded: false,
            note: {
                title: '',
                live: {
                    title: '',
                    thumb: ''
                }
            }
        }
    },
    metaInfo () {
        return {
            title: this.note.live.title
        }
    },
    components: { TContent },
    filters: {
        dateFormatter (value) {
            if (!value) return ''
            const date = new Date(value.replace(/-/g, '/'))
            return dateFormat(date, 'yyyy-MM-dd hh:mm')
        }
    },
    created() {
        const articleId = this.$route.params.id
        getNote(articleId).then(res => {
            this.note = res.data
            this.loading = false
        })
    }
};
</script>

<style lang="less">
    .divider {
        margin: 0px 2px;
    }
    .note-content {
        white-space: normal;
        p {
            margin-bottom: 0px;
        }
    }
</style>
