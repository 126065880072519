<template>
    <van-cell size="large" class="t-content">
        <template #title>
            <span>
                <slot name="title"></slot>
            </span>
        </template>
        <template #label>
            <van-row type="flex" justify="flex-start" class="meta">
                <van-col span="24">
                    <slot name="meta"></slot>
                </van-col>
            </van-row>

            <van-row type="flex" justify="flex-start" class="content">
                <van-col span="24">
                    <slot name="content"></slot>
                </van-col>
            </van-row>

            <van-row type="flex" justify="flex-start" class="footer">
                <van-col span="24">
                    <slot name="footer"></slot>
                </van-col>
            </van-row>
        </template>
    </van-cell>
</template>

<script>
export default {
    name: 'TContent',
};
</script>

<style lang="less">
    .meta {
        font-size: 15px;
        line-height: 23px;
    }
    .content {
        margin-top: 20px;
        line-height: 26px;
        color: #323233;
        word-break: break-all;
        white-space: pre-line;
        overflow-wrap: break-word !important;
        font-size: 16px;
        p {
            margin-bottom: 10px;
            margin-top: 0px;
        }
    }
    .footer {
        margin-top: 20px;
        font-size: 15px;
    }
    .t-content {
        .van-cell__label {
            font-size: 14px;
            line-height: 20px;
        }
        .van-cell__title {
            width: 100%;

            img {
                max-width: 100%;
            }
        }
        .van-cell__title {
            font-size: 20px;
            line-height: 28px;
        }
    }
</style>
