import { axios } from '@/utils/request'

export function storeNote(parameter) {
    return axios({
        url: 'notes',
        method: 'post',
        data: parameter
    })
}
export function deleteNote(noteId) {
    return axios({
        url: `notes/${noteId}`,
        method: 'delete'
    })
}

export function updateNote(noteId, parameter) {
    return axios({
        url: `notes/${noteId}`,
        method: 'put',
        data: parameter
    })
}

export function getNote(noteId) {
    return axios({
        url: `notes/${noteId}`,
        method: 'get'
    })
}

export function getNotes(params) {
    return axios({
        url: 'notes',
        method: 'get',
        params: params
    })
}

export function getLiveNote(liveId) {
    return axios({
        url: `lives/${liveId}/note`,
        method: 'get'
    })
}